import { SITE_NAME } from "@api/constants";
import fetchAllProducts from "@api/operations/fetch-all-products";
import Product from "@api/types/product";
import { GetServerSidePropsContext, InferGetServerSidePropsType } from "next";
import { NextSeo } from "next-seo";

import Container from "@components/Container";
import MainLayout from "@components/MainLayout";
import ProductCardGrid from "@components/ProductCardGrid";
import Spacer from "@components/Spacer";

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const { req } = context;
  const {
    cookies: { token },
  } = req;
  const products: Product[] = await fetchAllProducts(token);

  return {
    props: {
      products,
    },
  };
}

export type PropsType = InferGetServerSidePropsType<typeof getServerSideProps>;

export default function Home({ products }: PropsType) {
  return (
    <>
      <NextSeo title={SITE_NAME} description={SITE_NAME} />
      <Spacer size="md" />
      <Container>
        <ProductCardGrid products={products} />
      </Container>
    </>
  );
}

Home.Layout = MainLayout;
