import { SITE_PRIMARY_COLOR } from "@api/constants";
import { formatMoney } from "@api/money";
import { CFC } from "@api/types/cypress";
import Product from "@api/types/product";
import React, { useState } from "react";

import BuyButton from "@components/BuyButton";
import BuyCourseButton from "@components/BuyCourseButton";
import LinkButton from "@components/LinkButton";
import ProductVariantSelector from "@components/ProductVariantSelector";
import Spacer from "@components/Spacer";

const ProductCard: CFC<{ product: Product }> = ({ product, cy }) => {
  const { variants, thumbnail, title, description, itemType, mpn, course } = product;
  const [variant, setVariant] = useState(variants.length > 0 ? variants[0] : null);

  return (
    <>
      <div data-cy={cy} className="ProductCard">
        <img src={thumbnail.src} alt={title} />
        {variant ? (
          <>
            <div className="title">{variants.length > 0 ? `${title} - ${variant.title}` : title}</div>
            <div className="description" dangerouslySetInnerHTML={{ __html: description }} />
            <Spacer size="md" />
            {variants.length > 1 && (
              <>
                <Spacer size="sm" />
                <ProductVariantSelector variants={variants} onChange={setVariant} />
                <Spacer size="md" />
              </>
            )}
            <div className="actions">
              <div className="price">
                <span className="price-value">{formatMoney(variant.price)}</span>
                {` `}
                <span className="price-description">{variant.description}</span>
              </div>
              <div className="buttons">
                {itemType.toUpperCase() === "LMSTDS" ? (
                  <BuyCourseButton product={product} variant={variant} course={course} />
                ) : (
                  <BuyButton product={product} variant={variant} />
                )}
                <LinkButton href={`/course/id/${mpn}`} theme="secondary">
                  Course Details
                </LinkButton>
              </div>
            </div>
          </>
        ) : (
          <p className="error">There was an error loading this item</p>
        )}
      </div>
      <style jsx>
        {`
          .ProductCard {
            position: relative;
            padding-bottom: 75px;
            margin-bottom: 75px;
          }

          .ProductCard img {
            width: 100%;
            max-width: 100%;
          }

          .title {
            margin: 16px 0;
            font-family: "Bebas Neue", Regular;
            font-size: 30px;
            color: ${SITE_PRIMARY_COLOR};
          }

          .actions {
            position: absolute;
            bottom: 0;
            width: 100%;
          }

          .buttons {
            display: flex;
          }

          .actions-item {
            flex: 1;
          }

          .description {
            line-height: 26px;
            color: rgb(51, 51, 51);
          }

          .price {
            margin: 0 0 15px 0;
          }

          .price-value {
            font-family: "Bebas Neue", Regular;
            color: ${SITE_PRIMARY_COLOR};
            font-size: 24px;
          }

          .price-description {
            font-family: "Bebas Neue", Regular;
            color: rgb(0, 0, 0);
            font-size: 18px;
          }

          .error {
            color: red;
            text-align: center;
          }
        `}
      </style>
    </>
  );
};

export default ProductCard;
